<template>
  <div class="answer">
    <div class="checkbox" @click.prevent="$emit('answerToggled')">
      <CheckboxActive v-if="answer.isCorrect" />
      <CheckboxInactive v-else />
    </div>
    <!--  eslint-disable-next-line vue/no-mutating-props -->
    <input type="text" v-model="answer.text" :placeholder="`Answer option ${answerIndex}`" />
    <p v-if="canRemove" @click.prevent="$emit('removeAnswer')" class="remove">Remove</p>
  </div>
</template>

<script>
import CheckboxInactive from '@/assets/icons/checkbox_inactive.svg';
import CheckboxActive from '@/assets/icons/checkbox_active.svg';

export default {
  name: 'answer',
  props: {
    answer: {
      type: Object,
      required: true,
    },
    answerIndex: {
      type: Number,
      required: true,
    },
    canRemove: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    CheckboxInactive,
    CheckboxActive,
  },
};
</script>

<style lang="scss" scoped>
.answer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
      background: $light-grey;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  input {
    padding: 10px 5px;
  }
  .remove {
    padding-left: 20px;
    font-family: 'VWText Bold', $fallback-font-stack;
    font-size: 14px;
    color: $warning-red;
    cursor: pointer;
  }
}
</style>
