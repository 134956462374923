<template>
  <div class="section">
    <div class="top-row" @click="closed = !closed">
      <h3 class="section-title">
        {{ SectionTitle }}
      </h3>
      <CaratIcon class="carat-icon" :class="{ closed }" />
    </div>
    <div v-if="SectionDescription" class="section-description">{{ SectionDescription }}</div>
    <div class="section-content" :class="{ closed }">
      <div class="content-inner">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import CaratIcon from '@/assets/icons/carrot_up.svg';

export default {
  name: 'create-assessment-section',
  props: {
    SectionTitle: {
      type: String,
      required: true,
    },
    SectionDescription: {
      type: String,
      required: false,
    },
  },
  components: {
    CaratIcon,
  },
  data() {
    return {
      closed: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 1000px;
  .top-row {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .section-title {
      margin: 0 0 20px 0;
      font-size: 20px;
      font-weight: 300;
    }
    .carat-icon {
      width: 18px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
      &.closed {
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }
  .section-description {
    margin-bottom: 20px;
    font-family: 'VWText Light', $fallback-font-stack;
    font-size: 14px;
    font-weight: 300;
  }
  .section-content {
    padding: 0 30px;
    // 1px padding to deal with margin collapse on. content-inner
    padding: 1px 30px;
    margin-bottom: 20px;
    background-color: $primary-white;
    box-shadow: 0 2px 6px #0000001a;
    transition: max-height 0.3s ease-in-out;
    &.closed {
      max-height: 0;
      padding: 0 30px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      .content-inner {
        margin: 0;
        transition: margin 0.3s ease-in-out;
      }
    }
    .content-inner {
      margin: 30px 0;
      transition: margin 0.3s ease-in-out;
    }
  }
}
</style>
